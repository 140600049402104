export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";

export const GET_PLANT_MQTT_INTERMEDIATE = "GET_PLANT_MQTT_INTERMEDIATE";
export const RESET_PLANT_MQTT_INTERMEDIATE = "RESET_PLANT_MQTT_INTERMEDIATE";
export const GET_ALL_PLANTS = "GET_ALL_PLANTS";
export const RESET_ALL_PLANTS = "RESET_ALL_PLANTS";

export const GET_PLANT_ID = "GET_PLANT_ID";
export const RESET_PLANT_ID = "RESET_PLANT_ID";
export const ACDB_TABLE_IS_SHOWN = "ACDB_TABLE_IS_SHOWN";
export const ACDB_TABLE_SHOWN_RESET = "ACDB_TABLE_SHOWN_RESET";
export const PLANT_MQTT_WITH_RANGE = "PLANT_MQTT_WITH_RANGE";
export const RESET_MQTT_WITH_RANGE = "RESET_MQTT_WITH_RANGE";
export const GET_REPORT = "GET_REPORT";
export const RESET_GET_REPORT = "RESET_GET_REPORT";
export const PLANTSELECTOR_VISIBLE = "PLANTSELECTOR_VISIBLE";
export const RESET_VISIBLE_PLANTSELECTOR = "RESET_VISIBLE_PLANTSELECTOR";

export const GET_ALL_ACTIVE_ALARMS = "GET_ALL_ACTIVE_ALARMS";
export const RESET_ACTIVE_ALARMS = "RESET_ACTIVE_ALARMS";
export const ALARMS_IN_RANGE = "ALARMS_IN_RANGE";
export const RESET_ALARMS_IN_RANGE = "RESET_ALARMS_IN_RANGE";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const RESET_ALL_USERS = "RESET_ALL_USERS";

export const IS_MOBILE = "IS_MOBILE";
export const RESET_IS_MOBILE = "RESET_IS_MOBILE"

export const GET_ICON_PATH = "GET_ICON_PATH";
export const RESET_ICON_PATH = "RESET_ICON_PATH";

export const GET_DATA_FOR_ONE_DAY = 'GET_DATA_FOR_ONE_DAY';
export const RESET_DATA_FOR_ONE_DAY = 'RESET_DATA_FOR_ONE_DAY';