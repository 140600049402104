import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { getDataForOneDay } from '../actions/plantAction';
import ExcelJS from 'exceljs';
import moment from 'moment';

const FetchDataButton = ({ plantID, getDataForOneDay, dataForOneDay }) => {
  const handleFetchData = () => {
    if (plantID) {
      getDataForOneDay({ plantID })
        .then((fetchedData) => {
          console.log('Data fetched successfully:', fetchedData);
          generateExcel(fetchedData);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      console.log('No plantID provided');
    }
  };

  useEffect(() => {
    if (dataForOneDay && dataForOneDay.length > 0) {
      console.log('Fetched Data for One Day:', dataForOneDay);
    } else if (dataForOneDay && dataForOneDay.length === 0) {
      console.log('DataForOneDay is empty:', dataForOneDay);
    } else {
      console.log('DataForOneDay is null or not updated');
    }
  }, [dataForOneDay]);

  const generateExcel = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('in');
  
    // Add Plant Name Heading
    const plantName = data.length > 0 ? data[0].plantName : 'Unknown Plant';
    const plantNameRow = worksheet.addRow([plantName]);
    plantNameRow.font = { size: 16, bold: true };
    worksheet.mergeCells('A1:L1');
    plantNameRow.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Add Headers
    const headers = [
      'DateTime',
      'INV_1(Power)',
      'INV_2(Power)',
      'INV_3(Power)',
      'INV_4(Power)',
      'Active Power Generation',
      'W/m2 Tilit or Horizontal',
      'Ambient Temp. (Deg.C)',
      'Wind Speed',
      'Mod.Temp.(Deg.C)',
      'Relativity Humidity',
      'Wind Direction',
    ];
    const headerRow = worksheet.addRow(headers);
    headerRow.font = { size: 12, bold: true };
    headerRow.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Process data and add rows
    data.forEach((item) => {
      const transformer = item.transformer?.[0];
      const acdb = transformer?.acdb?.[0];
      const inverter = acdb?.inverter || transformer?.inverter;
      const vcb = item.vcb?.[0];
      const wms = item.wms || [];
  
      const getTagValue = (tags, tagID) => {
        return tags?.find((tag) => tag.tagID === tagID)?.tagValue ?? '';
      };
  
      const findWmsTagByID = (wmsArray, tagID) => {
        for (let wmsItem of wmsArray) {
          const tagValue = wmsItem.tags?.find(tag => tag.tagID === tagID)?.tagValue;
          if (tagValue !== undefined) return tagValue;
        }
        return ''; // Return empty string if not found
      };
  
      const rowData = [
        moment(item.mqttTime).format('DD-MM-YYYY HH:mm'), 
        parseFloat(getTagValue(inverter?.[0]?.tags, 'ACT_PWR')) || 0, 
        parseFloat(getTagValue(inverter?.[1]?.tags, 'ACT_PWR')) || 0, 
        parseFloat(getTagValue(inverter?.[2]?.tags, 'ACT_PWR')) || 0,
        parseFloat(getTagValue(inverter?.[3]?.tags, 'ACT_PWR')) || 0, 
        parseFloat(vcb?.tags?.find((tag) => tag.tagID === 'TTL_ACT_PWR')?.tagValue) || 0, 
        parseFloat(findWmsTagByID(wms, 'IRRADIANCE')) || 0,             
        parseFloat(findWmsTagByID(wms, 'AMB_TEMP')) || 0,               
        parseFloat(findWmsTagByID(wms, 'WIND_SPEED')) || 0,             
        0,                                                            
        0,                                                             
        0,                                                             
      ];
  
      const dataRow = worksheet.addRow(rowData);
      dataRow.font = { size: 10 };
  
      dataRow.eachCell((cell) => {
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });

      worksheet.addRow([]);

    });
  
    // Auto-width for columns
    worksheet.columns = headers.map((header) => ({ width: header.length + 5 }));
  
    // Save the Excel file
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
  
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const today = new Date();
      const formattedDate = today.toISOString().slice(0, 10).replace(/-/g, '_');
      const filename = `TodayReport_${plantName}_${formattedDate}.xlsx`;
      link.download = filename;
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      console.log(`Excel file "${filename}" created and downloaded successfully!`);
    } catch (error) {
      console.error('Error writing Excel file:', error);
    }
  };
  
  return (
    <Button color="blue" onClick={handleFetchData}>
      Download Today Report
    </Button>
  );
};

const mapStateToProps = (state) => ({
  plantID: state.plantID ? state.plantID.data : null,
  dataForOneDay: state.oneDayReport ? state.oneDayReport.dataForOneDay : [],
});

export default connect(mapStateToProps, { getDataForOneDay })(FetchDataButton);
